<template >    
<div>
            <!-- <div class="conter">
              <div class="top">
              <div >首页</div>
              <div :plain="true" @click="info_click">社区精选</div>
              <div :plain="true" @click="info_click">商家入驻</div>
              <div  :plain="true" @click="info_click">创业者服务</div>
              <div :plain="true" @click="info_click">关于我们</div>
              </div>
              </div> -->
               <div class="log">
<img src="guanw.jpg" alt="">
              </div>
              <div class="bottom">
              <p>Copyright © 2011-2022  壹盒科技（东莞）科技有限公司 版权所有 保留一切权利 ||<a href="http://beian.miit.gov.cn/">粤ICP备20012519号</a>  </p>
              </div>
             </div> 
</template>
<script>

export default {
      name: "User",
  
    data() {
        return{

        }
     
        
    },
  
    methods:{
info_click(){
  this.$message({
          message: '敬请期待',
          type: 'success'
        });
}
    
    }
  
}
</script>
<style>
html,body{ 
      margin: 0;
    padding: 0;
}  
   .conter{
        display: flex;
    justify-content: flex-end;
        margin: 20px;
        }
  .top{
        display: flex;
    justify-content: space-around;
    width: 40%;
  }
  .top div{
    font-size: 15px;
    cursor:pointer;
  }
/* .log{ */
/* width: 500px; */

    /* margin: 0 auto; */
/* } */
.log img{
     width: auto;
	height: auto;
	width: 100%;
	height: 100%;
}
.bottom{
    margin: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.bottom p{
  font-size: 10px;

}
</style>